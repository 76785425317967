import autocomplete from 'autocompleter';
import htmx from 'htmx.org';

interface AnyAutoComplete {
  label: string;
  value: string;
}

(() => {
  htmx.defineExtension('any-auto-complete', {
    onEvent: (name, event) => {
      if (name !== 'htmx:afterProcessNode') {
        return;
      }

      const el = (event.target || event.detail.elt) as HTMLInputElement;
      const { hidden: hiddenId, url, userInput } = el.dataset;
      const hidden = hiddenId ? (document.getElementById(hiddenId) as HTMLInputElement) : null;
      let lastResult: AnyAutoComplete | undefined;

      const allowUserInput = userInput === 'true';
      const userInputDiv = !allowUserInput ? (document.getElementById('user-input') as HTMLDivElement) : null;
      const userInputEl = userInputDiv ? userInputDiv.querySelector('input') : null;

      const showUserInput = () => {
        if (userInputDiv && userInputEl) {
          userInputEl.value = '';
          userInputEl.setAttribute('required', 'required');
          userInputDiv.classList.remove('hidden');
          userInputEl.focus();
        }
      };
      const hideUserInput = () => {
        if (userInputDiv && userInputEl) {
          userInputEl.value = '';
          userInputDiv.classList.add('hidden');
          userInputEl.removeAttribute('required');
        }
      };

      if (url) {
        autocomplete<AnyAutoComplete>({
          input: el,
          minLength: 3,
          debounceWaitMs: 300,
          preventSubmit: 1,
          fetch: async (text, update) => {
            text = text.toLowerCase();
            const response = await fetch(`${url}?q=${text}`, {
              headers: {
                'X-Requested-With': 'XMLHttpRequest'
              }
            });
            if (response.ok) {
              const results = (await response.json()) as AnyAutoComplete[];
              if (results.length > 0) {
                lastResult = results[results.length - 1];
              }
              const suggestions = allowUserInput
                ? results.filter((result) => result.label.toLowerCase().includes(text))
                : results;
              update(suggestions);
            }
          },
          onSelect: (item) => {
            if (hidden) {
              hidden.value = item.value;
              if (!allowUserInput) {
                if (item.value === '0') {
                  showUserInput();
                } else {
                  hideUserInput();
                }
              }
            }
            el.value = item.label;
          }
        });
      }

      el.addEventListener('input', () => {
        if (!el.value && hidden) {
          hidden.value = '';
        }
      });

      if (!allowUserInput && hidden) {
        el.addEventListener('change', () => {
          if (el.value && !hidden.value) {
            if (hidden.value !== '0' && lastResult) {
              hidden.value = lastResult.value;
              el.value = lastResult.label;
            }
            showUserInput();
          }
        });
      }
    }
  });
})();
